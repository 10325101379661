@import '../../styles/colors.css';
@import '../../styles/fonts.css';
@import '../../styles/layout.css';

.container {
  background-color: var(--white);
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
}

.registration-login-spinner {
  margin-top: 15rem;
}

.auth-background {
  position: absolute;
  width: 100%;
  height: 100%;
  background: url('../../images/login-complete.png') right center;
  background-position: right 0% bottom 0%;
  background-size: contain;
  background-repeat: no-repeat;

  &.complete {
    background-image: url('../../images/login-complete.png');
  }

  &.partial {
    pointer-events: none;
    background-image: url('../../images/login-partial.png');
  }

  &.base {
    background-image: url('../../images/login-base.png');
  }
}

.spinner-container {
  width: 100%;
  height: 100%;
  position: absolute;
}

.inner-container {
  position: relative;
  background-color: var(--white);
  width: 50vw;
  min-width: 650px;
  height: 25%;
  padding-bottom: 3rem;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.06);
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
}

.header-container {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  margin-bottom: 2.5rem;
}

.login-header {
  color: var(--dark);
  display: flex;
  width: 100%;
  justify-content: center;
}

.sub-header {
  color: var(--gray);
  text-align: center;
}

.content-container {
  width: 20rem;
}

.unverified-content {
  padding: 3em 0;
  color: var(--dark);
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
}

.unverified-heading {
  font-size: 1rem;
  margin-top: 2rem;
  margin-bottom: 1rem;
  text-align: center;
}

.unverified-content-description {
  font-size: 0.875rem;
  width: 80%;
}

.logo-container {
  width: 160px;
}

.logo-offset {
  padding-top: 4.125em;
  padding-bottom: 3.75em;
}

.logo-image {
  position: relative;
  z-index: 100;
  width: 174px;
}

.link {
  color: var(--gray);
  text-align: center;
  margin-top: 2rem;
  text-decoration: underline;
}

.unverified-login-button {
  text-align: center;
}

.login-decision-container {
  margin-bottom: 8%;
}

.login-decision-break {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid rgba(153, 153, 153, 0.5);
  line-height: 0.1em;
  margin: 2em 0;
}

.login-decision-break span {
  color: var(--gray);
  background: var(--white);
  font-size: 0.9rem;
  padding: 0 2em;
}

.social-login-container {
  position: relative;
}

/* These styles match Google's branding requirements, do not try to creative here
https://developers.google.com/identity/branding-guidelines */

.social-login-link {
  text-decoration: none;
  border: none;
  width: 100%;
  padding: 0;
}

.social-login-button {
  height: 2.5rem;
  width: 100%;
  border-radius: 3px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.3);
  box-sizing: border-box;
  transition: background-color 0.218s, border-color 0.218s, box-shadow 0.218s;
  user-select: none;
  cursor: pointer;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  background-color: var(--deep-blue);
  color: var(--white);

  &.azure {
    background: var(--white);
    color: var(--dark);
  }
}

.social-icon {
  float: left;
  background-color: var(--white);
  border-radius: 1px;
  border: 1px solid #4285F4;
  padding: 0.5625rem;
  width: 2.5rem;
  height: 2.5rem;
  box-sizing: border-box;
  color: var(--dark);
}

.azure .social-icon {
  border-color: #FFF;
  cursor: pointer;
}

.social-button-content-wrapper {
  height: 100%;
  width: 100%;
}

.microsoft-icon {
  float: left;
  border-radius: 3px;
  padding: 1rem 1rem 1rem 1.25rem;
  width: 1.3em;
  height: 1.3em;
}

.social-icon-svg {
  width: 1.25rem;
  height: 1.25rem;
}

.social-button-content {
  font-family: Roboto, arial, sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 2.5rem;
  letter-spacing: 0.21px;
  margin-left: 0.375em;
  margin-right: 0.375em;
  vertical-align: top;
}

.nav-container {
  width: 50vw;
  min-width: 650px;
  margin-top: 2em;
  color: var(--gray);
}

.password-field-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.password-visible-toggle-container {
  display: flex;
  align-self: flex-end;
  margin-bottom: 0.5rem;
  padding-bottom: 0.25rem;
  border-bottom: 1px solid rgb(204, 204, 204);
}

.password-reset-label-container {
  display: flex;
  z-index: 200;
  color: var(--dark);
  cursor: pointer;
  margin-right: 15rem;
  margin-bottom: 1.5rem;
}

.password-reset-modal-container {
  display: flex;
  flex-direction: column;
}

.password-reset-logo-icon-container {
  display: flex;
  flex-direction: row;
  height: 6rem;
}

.password-reset-back-icon {
  display: flex;
  align-self: flex-start;
  width: 2rem;
}

.password-reset-logo {
  display: flex;
  align-self: flex-start;
  justify-content: center;
  flex: 1;
  margin-top: 1rem;
}

.password-reset-heading {
  display: flex;
  align-self: center;
  font-weight: 400;
  font-size: var(--fs-xxxl);
  color: var(--dark);
}

.password-reset-text {
  margin-top: 1.875rem;
  color: var(--dark);
  font-size: var(--fs-md);
}

.password-reset-email-container {
  margin-top: 1.625rem;
  border: 2px solid var(--ash);
  border-radius: 2px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.password-reset-email-input {
  display: flex;
  margin-left: 1rem;
  padding-right: 1rem;
  color: var(--silver);
  overflow: hidden;
}

.error-text {
  align-self: center;
  margin-top: 1rem;
  font-size: var(--fs-md);
  color: var(--error);
}

.registration-error-text {
  align-self: center;
  margin-top: 2.25rem;
  font-size: var(--fs-md);
  color: var(--negative);
}

/* TODO: [ATOM-997] On small browsers login footer overwrites text */

.login-footer {
  z-index: 200;
  line-height: var(--footerHeight);
  margin-top: auto;
}

.login-footer-element {
  
  &:not(:last-child):after {
    content: '  |  ';
    padding: 0.5em;
  }
  color: var(--gray);
  text-decoration: none;
  cursor: pointer;
}

.login-footer-dialog-content {
  white-space: pre-wrap;
}

.nav-logo {
  margin-top: 4px;
}

.nav-right-container {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  height: 100%;
  user-select: none;
  margin-top: -4px;
}

.nav-right-text {
  display: flex;
  color: var(--dark);
  align-content: center;
  margin-right: 1.25rem;
}

.nav-right-login {
  cursor: pointer;
  border: 1px solid var(--blue);
  border-radius: 2px;
  color: var(--blue);
  font-weight: 500;
  text-transform: uppercase;
  padding: 0.6125rem 1.425rem;
  margin-right: 1.875rem;
}

.heading-text {
  color: var(--dark);
  font-size: var(--fs-xxxl);
  margin-top: 6.5rem;
  margin-bottom: 2rem;
}

.registration-icon-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 15rem;
}

.registration-icon {
  display: flex;
  z-index: 100;
  justify-content: center;
  align-items: center;
  background-color: var(--typhoon);
  height: 36px;
  width: 36px;
  border-radius: 50%;

  &.active {
    background-color: var(--blue);
  }
}

.icon-horizontal-bar-container {
  display: flex;
  flex-direction: row;
  margin-top: -1.125rem;
}

.icon-horizontal-bar {
  border-top: 2px solid var(--typhoon);
  width: 15rem;
  margin-top: -1.125rem;
}

.icon-horizontal-bar-half {
  border-top: 2px solid var(--typhoon);
  width: 7rem;

  &.active {
    border-top: 2px solid var(--blue);
  }
}

.text-field-container {
  margin-top: 2rem;
  margin-bottom: 1rem;
  width: 22rem;
  color: var(--dark);

  &.creation {
    margin-top: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  &.entry {
    margin-top: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
}

.text-field {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.text-block {
  margin-top: 2rem;
}

.verify-email-button-section {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 2rem;
}

.verify-email-text {
  display: flex;
  color: var(--dark);
  margin-bottom: 1.25rem;
}

.generic-button-container {
  z-index: 100;
  width: 22rem;
}

.generic-button {
  cursor: pointer;
  border: none;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--deep-blue);
  color: var(--white);
  font-weight: 500;
  font-family: Roboto, arial, sans-serif;
  border-radius: 2px;
  text-transform: uppercase;
  width: 100%;
  height: 2.5rem;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.3);
}

.generic-button-secondary {
  cursor: pointer;
  border: none;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: var(--fs-md);
  font-family: Roboto, arial, sans-serif;
  border-radius: 2px;
  background-color: var(--white);
  width: 100%;
  height: 2.5rem;
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.3);
}

.registration-social-login-container {
  width: 22rem;
  z-index: 300;
}

.tenants-container {
  display: flex;
  flex-direction: column;
}

.tenant-selection-row-container {
  z-index: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 5rem;
  width: 27.5rem;
  cursor: pointer;
  user-select: none;
  background-color: var(--white);
  border-radius: 5px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
  margin-bottom: 1.25rem;
}

.tenant-selection-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 1rem;
  height: 3.125rem;
  width: 3.125rem;
  background-color: rgba(0, 125, 239, 0.1);
  border-radius: 50%;
}

.tenant-selection-name-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 1.25rem;
}

.tenant-selection-name {
  color: var(--dark);
  font-weight: 500;
}

.tenant-selection-domain {
  display: flex;
  color: var(--gray);
}

.tenant-navigation-button-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-right: 1.875rem;
  flex-grow: 1;
}

.tenant-selection-button {
  display: flex;
  color: var(--blue);
  font-weight: 500;
  text-transform: uppercase;
}

.tenant-creation-button-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 2rem;
  width: 22rem;
}

.tenant-creation-input-container {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  width: 22rem;
  margin-top: 1rem;
}

.tenant-creation-input-label {
  display: flex;
  color: var(--dark);
  align-self: center;
  margin-left: 0.5rem;
}

.tenant-creation-checkbox-container {
  display: flex;
  justify-content: center;
  flex-flow: row nowrap;
  margin-top: 2rem;
  margin-right: 2.5rem;
  width: 22rem;
}

.tenant-creation-industry-container {
  display: flex;
  margin-top: 1rem;
  width: 22rem;
}

.tenant-creation-terms-content {
  color: var(--dark);
  font-size: var(--fs-md);
  margin-left: 0.25rem;
  align-self: center;
}

.tenant-creation-error-text {
  align-self: center;
  margin-top: 2.25rem;
  font-size: var(--fs-md);
  color: var(--negative);
}

.tenant-selection-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  z-index: 100;
  margin-bottom: 2rem;
  width: 100%;
}

.tenant-decision-container {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}

.tenant-selection-link {
  color: var(--blue);
  cursor: pointer;
  padding: 0 1ch;
}

.workspace-selection-container {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  z-index: 100;
  margin-bottom: 2rem;
  width: 100%;
}

.social-login-link:disabled {
  opacity: 0.6;
}
