@import '../../styles/layout.css';
@import '../../styles/fonts.css';
@import '../../styles/colors.css';

:root {
  --navigationBarHeight: 2.5rem;
  --headerOffset: 180px;
  --containerOffset: calc(var(--fixedHeaderOffset) + 0.25rem);
  --containerHeight: calc(100vh - var(--containerOffset));
}

.portal-wrapper {
  overflow: hidden;
}

.navigation-bar-container {
  box-sizing: border-box;
  display: flex;
  position: absolute;
  top: 0;
  margin-top: var(--topNavHeight);
  z-index: 200;
  width: 100%;
  flex-flow: row nowrap;
  justify-content: space-between;
  background-color: var(--purple);
  height: var(--subNavHeight);
  padding-right: 1rem;
}

.back-navigation-container {
  display: flex;
  flex-flow: row nowrap;
  line-height: 3.125rem;
  height: 3.125rem;
  width: 12rem;
  align-items: center;
  cursor: pointer;
  user-select: none;
  color: var(--white);
  font-size: var(--fs-lg);

  & .back-button-icon {
    display: flex;
    padding-left: 1.625rem;
  }

  & .back-button-text {
    display: flex;
    font-size: var(--fs-lg);
    padding-left: 1rem;
  }
}

.portal-content {
  box-sizing: border-box;
  margin-top: var(--fixedHeaderOffset);
  height: var(--containerHeight);
  overflow: auto;
}

.left-pane-container,
.right-pane-container {
  display: inline-block;
  height: 100%;
  overflow: auto;
}

.left-pane-container {
  width: var(--sidePaneWidth);
  background-color: var(--typhoon);
}

.right-pane-container {
  width: calc(100% - var(--sidePaneWidth));
}
