@import '../../styles/layout.css';
@import '../../styles/fonts.css';
@import '../../styles/colors.css';

:root {
  --navigationBarHeight: 2.5rem;
  --headerOffset: 180px;
  --containerHeight: calc(100vh - var(--containerOffset));
  --containerOffset: calc(var(--fixedHeaderOffset) + 0.25rem);
}

.portal-wrapper {
  overflow: hidden;
}

.navigation-bar-container {
  box-sizing: border-box;
  display: flex;
  position: absolute;
  top: 0;
  margin-top: var(--topNavHeight);
  z-index: 200;
  width: 100%;
  flex-flow: row nowrap;
  justify-content: space-between;
  background-color: var(--purple);
  height: var(--subNavHeight);
  padding-right: 1rem;
}

.back-navigation-container {
  display: flex;
  flex-flow: row nowrap;
  line-height: 3.125rem;
  height: 3.125rem;
  width: 12rem;
  align-items: center;
  cursor: pointer;
  user-select: none;
  color: var(--white);
  font-size: var(--fs-lg);

  & .back-button-icon {
    display: flex;
    padding-left: 1.625rem;
  }

  & .back-button-text {
    display: flex;
    font-size: var(--fs-lg);
    padding-left: 1rem;
  }
}

.portal-container {
  box-sizing: border-box;
  margin-top: var(--fixedHeaderOffset);
  height: var(--containerHeight);
  padding-bottom: 1rem;
  overflow: auto;
}

.schemas-container {
  display: flex;
  flex-flow: row wrap;
}

.schema-tile-container {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  border: 1px solid var(--ash);
  margin-top: 1.25rem;
  border-radius: 5px;
  min-height: 19.75rem;
  width: 20rem;
  margin-left: 1.25rem;
}

.schema-tile-title-container {
  display: flex;
  align-items: center;
  font-size: var(--fs-lg);
  max-width: 20rem;
  margin-top: 1rem;
  padding: 0 1.5rem;

  & .schema-tile-title {
    margin-left: 1rem;
  }
}

.schema-tile-details-container {
  margin-bottom: 0.5rem;
  padding: 1rem 1.5rem 0.5rem 1.5rem;
}

.schema-tile-details-row {
  display: flex;
  flex-direction: row;
  margin: 0.5rem 0;
  width: 100%;
  color: var(--gray);
}

.schema-tile-details-label {
  display: flex;
  width: 50%;

  &:after {
    content: ':';
  }
}

.schema-tile-details-value {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 50%;
}

.schema-tile-status-text {
  padding-left: 1ch;
}

.schema-tile-button-container {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-end;
  padding-left: 1.5rem;
  flex: 1;
}

.schema-tile-button {
  text-transform: uppercase;
  color: var(--blue);
  font-weight: 500;
  user-select: none;
  cursor: pointer;
  margin-bottom: 0.875rem;
}

.section-title {
  font-size: var(--fs-sm);
  color: var(--dim);
  padding-top: 1rem;
  padding-bottom: 0.5rem;
}

.color-container {
  border: 1px solid var(--ash);
  padding: 1rem 3rem;
  display: flex;
  justify-content: center;
  align-items: space-between;
  flex-flow: row wrap;
}

.color-swatch {
  font-size: var(--fs-xxxl);
  border: 3px solid var(--white);
  border-radius: 1.5rem;
  width: 1.5rem;
  height: 1.5rem;
  margin: 4px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;

  &.selected {
    border-color: var(--blue);
  }

  &:hover {
    border-color: var(--blue);
    cursor: pointer;
  }
}

.schema-icon {
  font-size: var(--fs-xxxl);
  border: 6px solid var(--white);
  border-radius: 2rem;
  width: 2.25rem;
  height: 2.25rem;
  margin: 1px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;

  &.selected {
    border-color: rgba(0, 125, 239, 0.3);
  }

  &:hover {
    border-color: rgba(0, 125, 239, 0.3);
    cursor: pointer;
  }
}

.type-container {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.type-tile {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding: 0.5rem;
  margin-right: 1rem;
  color: var(--gray);

  &:hover {
    cursor: pointer;
  }

  &.selected {
    color: var(--blue);
    background-color: var(--blueHighlight);
  }
}

.type-tile-text {
  padding-left: 0.5rem;
  font-size: var(--fs-md);
}

.icon-buttons-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}
